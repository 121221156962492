.container {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0;
    width: 200px;
    height: 220px;
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    cursor: pointer;
}
.container::after {
    content: 'В разработке';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;

}




.container:nth-child(2)::after{
    content: none;
}
.container:first-child::after{
    content: none;
}

