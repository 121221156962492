.container {
  position: absolute;
  z-index: 6;
  width: 50vw;
  height: 100vh;
  top: 0;
  background-color: #fff;
  transition: all 0.5s ease;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 8vh;
}

.headerId{
  display: flex;
  font-size: 20px !important;
  color: black;
}

.headerId span{
  color: red;
  margin-left: 5px;
}

.headerId h1{
  margin-left: 15px;
  font-size: 20px !important;
}

.listsBox {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  max-height: 715px;
  margin-right: 20px;
}
.listsBox::-webkit-scrollbar {
  width: 30px;
}

/* Track */
.listsBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 30px;
}

/* Handle */
.listsBox::-webkit-scrollbar-thumb {
  background: #ff9c20;
  border-radius: 30px;
}

/* Handle on hover */
.listsBox::-webkit-scrollbar-thumb:hover {
  background: #ff9c10;
}

.bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;

  bottom: 0;
  padding: 0px 20px;
  background-color: #fff;
  z-index: 99;
}
.total {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  /* margin-bottom: 15px; */
}
.infoBox {
  display: flex;
  padding: 8px 0;
  margin: 0 20px;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}
