.container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.3s ease;
}

.content {
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
}

.header {
  display: flex;
  padding: 15px 30px;
  justify-content: space-between;
  /* height: 25vh; */
  /* background-color: red; */
}

.leftBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* width: 30%; */
}

.right {
  /* width: 40%; */
  height: 100%;
}

.right img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.nameBox {
  display: flex;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 20px;
}

.total {
  display: flex;
  justify-content: flex-end;
  padding: 10px 40px;
  position: absolute;
  width: 100%;
  bottom: 100px;
}

.total h1{
  font-size: 30px !important;
}

.btnBox {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px;
}
