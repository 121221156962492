.container{
    display: flex;
    padding: 20px 5%;
    flex-direction: column;
}

.transactionHeader{
    display: flex;
    justify-content: space-between;
}

.lists{
    margin-top: 10px;
    width: 100%;
}
.container {

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    height: 50%;
    background-color: #fff;
    border-radius: 15px;
    padding: 8% 0;
}

.btnBox{
    display: flex;
    width: 50%;
    justify-content: space-between;
}

