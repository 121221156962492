.inputBox {
    display: flex;
    align-items: center;
    height: 55px;
    width: 450px;
    background-color: #fbf9f9;
    border-radius: 8px;
}
.label {
    margin-bottom: 10px;
    padding-left: 15px;
}
.icon {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input {
    border: none;
    outline: none;
    height: 100%;
    background-color:#f7f7f7;
    width: 100%;
    padding: 0 15px;
    border-radius: 8px;
    font-size: 16px;
}

.container{
    margin-top: 20px;
}