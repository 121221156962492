.header {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-left: 30px;
  padding: 0 5%;
}

.header .container p {
  text-align: center !important;
}
