.container {
  padding: 0 5%;
  width: 100%;
}
.infoBox {
  display: flex;
  padding: 8px 0px;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}
.listBox {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}

.nameBox {
  flex: 4;
  display: flex;
  align-items: center;
}

.same {
  flex: 2 1;
}

.same h1{
  text-align: right;
}
