.container {
  padding: 0 20px;
}
.infoBox {
  display: flex;
  padding: 8px 30px 8px 0;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
  margin-right: 20px;
}
.listBox {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-right: 20px;
  border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}

.nameBox {
  flex: 4;
  display: flex;
  align-items: center;
}

.same {
  flex: 1;
}

.overflowContainer::-webkit-scrollbar {
  width: 30px;
}

/* Track */
.overflowContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 30px;
}

/* Handle */
.overflowContainer::-webkit-scrollbar-thumb {
  background: #ff9c20;
  border-radius: 30px;
}

/* Handle on hover */
.overflowContainer::-webkit-scrollbar-thumb:hover {
  background: #ff9c10;
}
