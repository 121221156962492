.modal {
  padding: 60px 20px 0 20px;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  z-index: 999;
  overflow-y: auto;
}

.closeBtn{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c1c1c1;
  border-radius: 50%;
}

.popup {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  transition: opacity 0.1s linear;
}
