.container {
  text-align: -webkit-right;
  width: 80px;
}

.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 50px;
  background-color: #ff9c20;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.outline {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 80px;
  height: 50px;
  border: 1px solid #ff9c20;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.outline:hover {
  opacity: 0.7;
}
.primary:hover {
  opacity: 0.7;
}
