.titleBox {
    position: absolute;
    top: 30px;
    left: 6%;
}

.container{
    padding-top: 15px;
    position: relative;
}

.container>div:first-child{
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin-left: auto;
    margin-bottom: 20px;
}

.productBox {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 30px 6%;
}

.filterBox {
    display: flex;
    justify-content: space-between;
    padding: 0px 6%;
}