.container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    height: 50%;
    background-color: #fff;
    border-radius: 15px;
    padding: 8% 0;
}

.btnBox{
    display: flex;
    width: 50%;
    justify-content: space-between;
}

