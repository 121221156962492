.container {
    padding: 0 20px;
}
.infoBox {
    display: flex;
    padding: 8px 0px;
    border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}
.listBox {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid rgba(177, 160, 160, 0.35);
}
.itemImg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding: 0 5px;
    height: 50px;
}

.itemImg img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin-right: 5px;
}

.nameBox {
    flex: 1.4;
    display: flex;
    align-items: center;
}

.btnBox {
    flex: 1;
}

.rightBox{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}