.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 20px;
}

.topBox {
    min-height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}
.bottomBox {
    min-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
}