.container {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    cursor: pointer;
    gap: 5px;
}
.count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    font-size: 18px;
    border-radius: 8px;
    color: #484848;
    font-family: "FuturaPTMedium";
    background: rgba(235, 235, 235, 0.46);
}
.container button {
    width: 50px;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    font-weight: 700;
    background: rgba(235, 235, 235, 0.46);
}

.minus {
    color: #FF2D20;
    padding-bottom: 5px;
}
.plus {
    color:#1E9F1B;
}