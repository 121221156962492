.modal {
  padding: 20px;
  width: 550px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  z-index: 999;
}

.keyBoard{
  width: 100%;
}

.keyBoard>div{
  bottom: -270px;
  background: white;
}

.popup {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 200px;
  z-index: 999;
  transition: opacity 0.1s linear;
}

.errorMessage{
  color: red;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buttons{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.labelInput{
  margin-bottom: 6px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}