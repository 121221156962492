.check {
  width: 300px;
  padding: 3rem 2rem 1.5rem 4rem;
}
.check > div {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}
.check hr {
  display: block;
  width: 100%;
  height: 4px;
  border-left: none;
  border-right: none;
  border-top: 1px dashed #000;
  border-bottom: 1px dashed #000;
  margin: 12px 0;
}
.check-block {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.2rem;
}

