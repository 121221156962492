.container {
    height: 100vh;
display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 20px;
}

.topBox {
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.topBox h1{
    margin-bottom: 50px;
}

.topBox>div h1{
    margin-bottom: 0 !important;
}

.registerBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.registerBtns button {
    width: 45%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.usersNameContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.usersNameContainer p {
    font-size: 1.6rem;
    color: black;
    min-height: 40px;
}

.registerBtns button:first-child{
    margin-right: 20px;
}

.bottomBox {
    min-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
}