.numericKeyboard{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f3f3f3;
    padding: 20px;
}

.numericKeyboard__row{
    width: 315px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.numericKeyboard__row button{
    width: 32%;
    height: 100%;
    border: none;
    outline: none;
    background: #0000000c;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}

.numericKeyboard__row:last-child button:first-child{
    width: 66%;
}