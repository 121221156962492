.container{
    height: 100vh;
    width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
}

.logoBox {
    height: 45%;
    display: flex;
    align-items: center;
}

.bannerBox{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.containerSlider {
    width: 90%;
    border-radius: 16px;
    background-color: #0000001f;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cameraButtonsEnter{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.popup {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .45);
    height: 100vh;
    width: 100wh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
}

.modal {
    padding: 20px;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
}

.modal p {
    font-size: 24px;
    margin-top: 20px;
}
